@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
@import url('https://fonts.cdnfonts.com/css/ignis-et-glacies-sharp');


body {
  margin: 0;
  font-family: 'Orbitron', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #070707;

  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}